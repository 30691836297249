import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { SEO, Layout } from '@/components';
import PrismicComponent from '@/prismic/prismicComponent';
import mq from '@/styles/mq';

import { PageContext } from '@/domain/pageContext';
import { PLegal } from '@/domain/Legal';

import theme from '@/styles/theme';


const LegalContainer = styled(motion.section)`
    padding: 3rem 6rem;
    a{
        text-decoration: underline;
        transition: .3s;
        &:hover{
            color: ${theme.colors.colorGreen};
        }
    }
    h1, h2, h3, h4{
        margin-top: 2rem;
    }
    h3{
        color: ${theme.colors.colorGreen};
        font-size: 1.2rem;
    }
    ${mq.mqMax('lg')}{
        h3{
            font-size: 1rem;
        }
    }
    ${mq.mqMax('md')}{
        padding: 3rem;
        
    }
`;


interface Props {
    pageContext: PageContext;

    data: {
        prismicMentionspage: PLegal;
    }
}



const Mentions = ({ pageContext, data: { prismicMentionspage } }: Props): JSX.Element => {
    const controls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const { dataHeader, alternate } = pageContext;
    const { uid, lang, alternate_languages } = prismicMentionspage;
    const {
        seo_url,
        seo_title,
        seo_description,
        seo_keywords,
        content
    } = prismicMentionspage.data;

    const selectLang = {
        lang: lang,
        uid: uid,
        alternate_languages: alternate_languages
    }


    const seo = {
        lang: lang.split('-')[0],
        url: `${lang.split('-')[0]}/${seo_url.text}`,
        title: seo_title.text,
        description: seo_description.text,
        keywords: seo_keywords.text
    }
    
    const item = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }

    useEffect(() => {
        if (inView === true) {
            controls.start('visible')
        }
    }, [inView])

    const linkAlternate = alternate?.map(data => ({
        lang: data.lang,
        url: `${data.lang.split('-')[0]}/${data.uid}`
    }))

    return (
        <Layout header={dataHeader} selectLang={selectLang} >
            <SEO lang={seo.lang} title={`Global Electrics | ${seo.title}`} url={seo.url} description={seo.description} keywords={seo.keywords} alternate={linkAlternate} />
            <LegalContainer
                ref={ref}
                initial="hidden"
                animate={controls}
            >
                <motion.div
                    variants={item}
                    transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                >
                    <PrismicComponent content={content.raw} />
                </motion.div>
            </LegalContainer>
        </Layout>
    );
};

export default Mentions;


export const query = graphql`
query ($id: String!) {
  prismicMentionspage(id: {eq: $id}) {
        uid
        lang
        alternate_languages {
          lang
          uid
        }
        data {
          content{
              raw
          }
          seo_url {
            text
          }
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_keywords {
            text
          }
        }
  }
}
`